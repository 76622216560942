@import "../../../data/styles.css";

.exp-date {
	font-family: var(--secondary-font);
	color: var(--primary-color);
	font-size: 16px;
	margin-bottom: 1em;
}

.exp-description {
	color: var(--secondary-color);
}