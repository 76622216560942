.contact-form-container {
	display: flex;
	flex-wrap: wrap;
    width: 60%;
	align-items: stretch;
	padding-top: 30px;
	padding-bottom: 30px;
	margin-left: -35px;
	margin-right: -35px;
}

.contact-form-input {
	width: calc(100% / 2);
	box-sizing: border-box;
	padding: 10px;
	overflow: hidden;
}
.flex-center {
    display: flex;
    justify-content: center;
    justify-items: center;
    flex-direction: column;
}

.success { 
    background-color: green;
}

.error {
    background-color: red;
}

@media (max-width: 600px) {
	.contact-form-input {
		width: calc(100%);
	}
}
